@import "../../css/main";
@import "../../css/slider";

$marginBlock: 104px;
$mobilePadding: 0 16px;

.index-page {
    position: relative;

    &__spring {
        position: absolute;
        top: 100px;
        left: calc(50% - 1355px);
        z-index: -1;
    }

    &__circle {
        position: absolute;
        filter: blur(6px);
        border-radius: 100%;

        &.levitation.num1 {
            right: calc(50% - 460px);
            top: 0;
            width: 55px;
            height: 55px;
            background: linear-gradient(180deg, #66ffa3 0%, #009c3e 100%);
            animation: do-levitation 1.8s alternate ease-in-out infinite;
        }

        &.levitation.num2 {
            right: calc(50% - 660px);
            top: 75px;
            width: 30px;
            height: 30px;
            background: #3b3468;
            animation: do-levitation 1.8s 0.5s alternate ease-in-out infinite;
        }

        &.levitation.num3 {
            left: calc(50% - 660px);
            top: 55px;
            width: 47px;
            height: 47px;
            background: linear-gradient(180deg, #ff81a6 0%, #fc165b 100%);
            animation: do-levitation 1.8s 1.5s alternate ease-in-out infinite;
        }
    }

    &__banner {
        display: flex;
        align-items: center;

        &-left {
            max-width: 510px;
            width: 100%;
            margin-right: 62px;
            flex-shrink: 0;

            &-label {
                //
            }

            &-title {
                //
            }

            &-description {
                //
            }

            &-btn {
                //
            }
        }

        &-right {
            position: relative;

            &-gradient {
                position: absolute;
                top: 150px;
                right: 200px;
                bottom: 0;
                left: 0;
                margin: auto;
                width: 866px;
                height: 650px;
                background: linear-gradient(231.9deg, rgba(0, 26, 255, 0.2) -2.35%, rgba(110, 229, 194, 0.2) 89.59%);
                filter: blur(150px);
                z-index: 1;
            }

            img {
                position: relative;
                z-index: 2;
            }
        }
    }

    &__companies {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background: #ffffff;
        padding: 56px 0;
        margin-bottom: $marginBlock;
        z-index: 2;

        &-title {
            line-height: 28px;
            text-transform: uppercase;
            color: $color-dark-grey;
            margin-bottom: 24px;
        }

        &-list {
            display: flex;
            align-items: center;
            animation: doit 60s linear both infinite;

            @keyframes doit {
                0% {
                    transform: translateX(0%);
                }
                100% {
                    transform: translateX(-100%);
                }
            }

            &-container {
                display: flex;
                align-items: center;
            }

            &-item {
                margin-right: 90px;
                flex-shrink: 0;

                &:last-child {
                    margin-right: 0;
                }

                img {
                    height: 28px;
                }
            }
        }
    }

    &__compliant {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: $marginBlock;

        &-title {
            font-weight: bold;
            font-size: 36px;
            line-height: 44px;
            text-align: center;
            margin-bottom: 24px;
        }

        &-description {
            width: 90%;
            max-width: 967px;
            font-size: 18px;
            line-height: 32px;
            text-align: center;
            color: $color-dark-grey;
            margin-bottom: 24px;
        }
    }

    &__verification {
        display: flex;
        align-items: center;
        margin-bottom: $marginBlock;

        &-left {
            width: 100%;
            max-width: 461px;
            margin-right: 24px;

            &-title {
                font-weight: bold;
                font-size: 36px;
                line-height: 50px;
                margin-bottom: 24px;
            }

            &-description {
                font-size: 18px;
                line-height: 32px;
                color: $color-dark-grey;
            }
        }

        &-right {
            max-width: 655px;
            width: 100%;
            background: rgba(255, 255, 255, 0.5);
            //filter: blur(74px);
            border-radius: 10px;
            padding: 64px 38px 44px 38px;
            //background: radial-gradient(closest-side, #e66465, rgba(255, 255, 255, 0.5));
            background: url("/src/img/verification/gradient.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            display: flex;
            flex-wrap: wrap;

            &-item {
                display: flex;
                align-items: center;
                background: #ffffff;
                border-radius: 4px;
                padding: 12px;
                line-height: 19px;
                color: $color-dark-blue;
                margin-right: 16px;
                margin-bottom: 20px;

                img {
                    margin-right: 8px;
                }
            }
        }
    }

    &__map {
        //
    }

    &__benefits {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: $marginBlock;

        &-title {
            max-width: 624px;
            font-weight: bold;
            font-size: 36px;
            line-height: 50px;
            text-align: center;
            margin-bottom: 24px;
        }

        &-list {
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 24px;
            grid-row-gap: 24px;
        }
    }

    &__integration {
        padding: $marginBlock 0;
        background: #ffffff;
        margin-bottom: $marginBlock;

        .content {
            display: flex;
            align-items: center;
        }

        &-left {
            width: 100%;
            max-width: 558px;
            margin-right: 42px;

            &-title {
                font-weight: bold;
                font-size: 36px;
                line-height: 50px;
                margin-bottom: 24px;
            }

            &-description {
                font-size: 18px;
                line-height: 32px;
                color: $color-dark-grey;
                margin-bottom: 32px;
            }
        }

        &-right {
            width: 100%;
            max-width: 505px;

            img {
                width: 100%;
            }
        }
    }

    &__contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: $marginBlock;

        &-title {
            font-weight: bold;
            font-size: 36px;
            line-height: 50px;
            margin-bottom: 16px;
        }

        &-description {
            font-size: 18px;
            line-height: 32px;
            color: $color-dark-grey;
            margin-bottom: 16px;
            text-align: center;
        }

        &-form {
            display: flex;
            width: 100%;
            max-width: 582px;

            &-label {
                flex-grow: 1;
                margin-right: 16px;

                &-input {
                    width: 100%;
                    background: #ffffff;
                    border: 1px solid $color-light-grey;
                    border-radius: 4px;
                    box-sizing: border-box;
                    padding: 22px 16px;
                }
            }

            &-btn {
                flex-shrink: 0;
                display: flex;
                align-items: center;
                background: #252534;
                border-radius: 4px;
                color: #ffffff;
                padding: 0 50px;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 980px) {
    $marginBlock: 56px;

    .index-page {
        //

        &__banner {
            padding: $mobilePadding;
            flex-direction: column;

            &-left {
                margin-right: 0;
                margin-bottom: 42px;

                &-title {
                    //font-size: 36px;
                    //line-height: 44px;
                }
            }

            &-right-gradient {
                display: none;
            }
        }

        &__companies {
            margin-bottom: $marginBlock;

            &-title {
                font-size: 14px;
                line-height: 24px;
            }
        }

        &__compliant {
            padding: $mobilePadding;
            margin-bottom: $marginBlock;

            &-title {
                font-size: 30px;
                line-height: 40px;
            }

            &-description {
                font-size: 16px;
                line-height: 26px;
            }

            &-slider-arrow {
                display: none;
            }

            &-slider-viewport-container-slide {
                padding: 24px;

                &-img {
                    max-width: 90px;
                    max-height: 90px;
                }

                &-title {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 28px;
                }

                &-description {
                    line-height: 26px;
                }
            }
        }

        &__verification {
            padding: $mobilePadding;
            flex-direction: column;
            margin-bottom: $marginBlock;

            &-left {
                margin-right: 0;
                margin-bottom: 24px;

                &-title {
                    font-size: 30px;
                    line-height: 40px;
                }

                &-description {
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            &-right {
                padding: 24px 16px;

                &-item {
                    font-size: 14px;
                    line-height: 17px;
                    padding: 11px 12px;
                }
            }
        }

        &__benefits {
            padding: $mobilePadding;
            margin-bottom: $marginBlock;

            &-title {
                font-size: 30px;
                line-height: 40px;
            }

            &-list {
                grid-template-columns: 1fr;

                &-item-title {
                    font-size: 22px;
                    line-height: 28px;
                }
            }
        }

        &__integration {
            padding: $mobilePadding;
            padding-top: $marginBlock;
            padding-bottom: $marginBlock;
            margin-bottom: $marginBlock;

            .content {
                flex-direction: column;
            }

            &-left {
                margin-right: 0;
                order: 1;

                &-title {
                    font-size: 30px;
                    line-height: 40px;
                }

                &-desciption {
                    line-height: 26px;
                }
            }

            &-right {
                margin-bottom: 24px;
            }
        }

        &__contact {
            padding: $mobilePadding;
            margin-bottom: $marginBlock;

            &-title {
                font-size: 30px;
                line-height: 50px;
            }

            &-description {
                font-size: 16px;
                line-height: 26px;
            }

            &-form {
                flex-direction: column;

                &-label {
                    margin-right: 0;
                    margin-bottom: 16px;
                }

                &-btn {
                    justify-content: center;
                    height: 60px;
                }
            }
        }
    }
}
