$color-bg: #f6f8ff;
$color-black: #252534;
$color-blue: #5578f8;
$color-blue-2: #7896ff;
$color-blue-3: #3f64e3;
$color-gray-1: #dadde9;
$color-dark-grey: #6e6e7d;
$color-light-grey: #e6e8ef;
$color-medium-grey: #8f9bb0;
$color-regular-grey: #e8ecfb;
$color-dark-blue: #3b3468;
