@import "../../css/colors";

.footer {
    padding: 56px 0;
    background: #e8ecfb;

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 16px;
    }

    &__column {
        //

        &-title {
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 16px;
        }

        &-item {
            display: block;
            font-size: 12px;
            line-height: 24px;
            color: $color-dark-blue;
        }

        &-icons {
            display: flex;
            align-items: center;
            margin-top: 16px;

            &-item {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }
    }
}

@media (max-width: 980px) {
    .footer {
        padding: 32px 0;

        .content {
            grid-template-columns: 1fr 1fr;
            row-gap: 32px;
            padding: 0 32px;
        }
    }
}
