body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

body {
    min-height: 100vh;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%;
    display: block;
}

ol,
ul {
    list-style: none;
}

input,
textarea {
    padding: 0;

    &:focus-visible {
        outline: none;
    }
}

input:focus {
    outline: none;
}
