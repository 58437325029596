@import "../../css/colors";

.layout__header {
    position: sticky;
    top: 0;
    z-index: 50;
    transition: 0.5s ease;

    &.sticky {
        background: #ffffff;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
    }
}

.c-header {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 28px 0;
    font-size: 16px;
    line-height: 19px;
    z-index: 100;

    &__logo {
        //

        img {
            //
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        margin-left: auto;
        //margin-right: 32px;

        &-item {
            margin-right: 32px;
            padding: 10px 0;
        }
    }

    &__auth {
        background: #ffffff;
        border: 2px solid $color-regular-grey;
        border-radius: 24px;
        padding: 12px 24px;
    }
}

.m-header {
    display: none;
    position: sticky;
    top: 0;
    height: 70px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
    padding: 0 16px;
    box-sizing: border-box;
    z-index: 100;

    &__logo {
        //
    }

    &__burger {
        margin-left: auto;
        padding: 16px;
        margin-right: -16px;

        div {
            width: 18px;
            height: 2px;
            margin-bottom: 3px;
            background: $color-dark-blue;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.m-menu {
    display: none;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 70px);
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
    transition: 0.2s ease;

    &__block {
        display: flex;
        align-items: center;
        flex-direction: column;
        max-width: 500px;
        width: 100%;
        height: 100%;
        padding: 32px 24px;
        background: #ffffff;
        transform: translateX(-100%);
        transition: 0.2s ease;
        overflow-y: auto;

        &-nav {
            width: 100%;
            margin-bottom: 54px;

            &-item {
                //
            }
        }

        &-btn {
            width: 100%;
            margin-top: auto;
        }
    }
}

.open.m-menu {
    opacity: 1;
    visibility: visible !important;

    .m-menu__block {
        transform: translateX(0);
    }
}

.mob-acc {
    transition: 0.2s ease;
    overflow: hidden;

    &__title {
        display: flex;
        align-items: center;
        padding: 16px 0;

        &-text {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: $color-black;
        }

        &-icon {
            margin-left: auto;
            margin-right: 3px;
            transition: 0.2s ease;
        }
    }

    &__body {
        //

        &-list {
            display: flex;
            flex-direction: column;

            &-item {
                font-size: 14px;
                line-height: 17px;
                color: $color-dark-grey;
                border-radius: 4px;
                padding: 12px 12px 12px 24px;

                &:active {
                    background: $color-regular-grey;
                }
            }
        }
    }
}

.visible .mob-acc {
    &__title {
        //

        &-icon {
            transform: rotateZ(-90deg);
        }
    }
}

@media (max-width: 980px) {
    .m-menu {
        display: block;
    }

    .c-header {
        display: none;
    }

    .m-header {
        display: flex;
    }
}
