.slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;

    &__viewport {
        width: 100%;
        overflow: hidden;
        margin-bottom: 24px;

        &-container {
            display: flex;
            transition: transform 0.6s ease;

            &-slide {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: #ffffff;
                border: 1px solid #e6e8ef;
                border-radius: 8px;
                padding: 40px;
                margin-right: 20px;
                flex-shrink: 0;
                box-sizing: border-box;

                &:last-child {
                    margin-right: 0;
                }

                &-img {
                    max-width: 100px;
                    max-height: 100px;
                    margin-bottom: 16px;
                }

                &-title {
                    font-size: 24px;
                    line-height: 28px;
                    color: $color-dark-blue;
                    text-align: center;
                    margin-bottom: 16px;
                }

                &-description {
                    line-height: 28px;
                    text-align: center;
                    color: $color-dark-grey;
                }
            }
        }
    }

    &__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        width: 48px;
        height: 48px;
        transform: translateY(-50%);
        background: #ffffff;
        border: 1px solid $color-light-grey;
        border-radius: 100px;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;

        &.prev {
            right: calc(100% + 8px);
        }

        &.next {
            left: calc(100% + 8px);

            img {
                transform: rotate(180deg);
            }
        }

        &.disabled {
            //opacity: 0.3;
            display: none;
        }
    }

    &__dots {
        display: flex;

        &-item {
            width: 10px;
            height: 10px;
            background: $color-light-grey;
            margin-right: 8px;
            border-radius: 100px;
            cursor: pointer;
            transition: 0.2s ease;

            &.active {
                background: $color-dark-blue;
                cursor: default;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
