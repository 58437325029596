@import "./fonts";
@import "./colors";
@import "./reset";

* {
    box-sizing: border-box;
}

body {
    background: $color-bg;
    font-family: "Inter", "system-ui", sans-serif !important;
    color: $color-black;
    overflow-x: hidden;
}

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;

    &__header {
        //
    }

    &__content {
        flex-grow: 1;
        overflow: hidden;
    }
}

.content {
    width: calc(100% - 24px);
    max-width: 1140px;
    margin: 0 auto;
}

.custom-btn {
    display: inline-block;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    background: $color-blue;
    border-radius: 100px;
    padding: 16px 48px;
    cursor: pointer;
    transition: 0.2s ease;
    user-select: none;
    min-width: 200px;
    text-align: center;

    &:hover {
        background: $color-blue-2;
    }

    &:active {
        background: $color-blue-3;
    }

    &_disabled {
        background: $color-gray-1;
    }
}

.fade-in {
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s ease;
}

.visible {
    opacity: 1;
    transform: translateY(0);
}

.page {
    //

    &-label {
        display: inline-block;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #6275a5;
        padding: 4px 8px;
        margin-bottom: 6px;
        background: #eaedff;
        border-radius: 4px;
    }

    &-title {
        font-weight: bold;
        font-size: 50px;
        line-height: 61px;
        margin-bottom: 24px;
    }

    &-description {
        font-size: 16px;
        line-height: 28px;
        color: $color-dark-grey;
        margin-bottom: 32px;
    }
}

.article {
    //

    &-title {
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        //text-align: center;
    }

    &-text-600 {
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        color: $color-dark-grey;
    }

    &-text-18 {
        font-size: 18px;
        line-height: 32px;
        color: $color-dark-grey;
    }

    &-text-16 {
        font-size: 16px;
        line-height: 32px;
        color: $color-dark-grey;
    }
}

.blue-block {
    padding: 72px 16px;
    background: $color-blue;

    &-title {
        max-width: 710px;
        width: 100%;
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        color: #ffffff;
        margin: 0 auto 24px auto;
    }

    &-description {
        max-width: 900px;
        width: 100%;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        margin: 0 auto;
    }
}

.grey-item {
    padding: 24px;
    background: #e8ecfb;
    border-radius: 8px;

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background: #dee3f3;
        border-radius: 3px;
        margin-bottom: 16px;
    }

    &-title {
        font-size: 24px;
        line-height: 28px;
        color: $color-dark-blue;
        margin-bottom: 16px;
    }

    &-description {
        line-height: 28px;
        color: $color-medium-grey;
    }
}

.list {
    //

    &__item {
        display: flex;
        align-items: center;
        padding: 16px;
        border: 1px solid $color-medium-grey;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 24px;

        &-num {
            width: 40px;
            flex-shrink: 0;
            margin-right: 24px;
            font-weight: 600;
            font-size: 36px;
            line-height: 32px;
        }

        &-text {
            //

            &-title {
                //
            }

            &-description {
                line-height: 26px;
            }
        }
    }
}

.accordion {
    border: 1px solid $color-blue;
    box-sizing: border-box;
    border-radius: 8px;
    height: 76px;
    overflow: hidden;
    transition: 0.2s ease;

    &__title {
        display: flex;
        align-items: center;
        font-size: 24px;
        line-height: 28px;
        color: $color-blue;
        padding: 25px 0 22px 0;
        margin: 0 24px;
        border-bottom: 1px solid $color-light-grey;
        cursor: pointer;

        &-plus {
            margin-left: auto;
            transition: 0.3s ease;
        }
    }

    &__body {
        padding: 16px 24px 24px 24px;

        &-text {
            font-size: 18px;
            line-height: 32px;
            color: $color-dark-grey;
        }
    }
}

.visible {
    background: #ffffff;

    .accordion {
        &__title {
            //

            &-plus {
                transform: rotateZ(45deg);
            }
        }
    }
}

.dropdown {
    $dropdown: &;
    position: relative;

    &:hover {
        #{$dropdown}__body {
            opacity: 1;
            visibility: visible;
        }

        #{$dropdown}__title {
            //

            &-img {
                transform: rotateX(180deg);
            }
        }
    }

    &__title {
        display: flex;
        align-items: center;
        cursor: default;

        &-text {
            //
        }

        &-img {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s ease;
        }
    }

    &__body {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 100%;
        transition: 0.2s ease;
        padding-top: 38px;

        &-list {
            min-width: 256px;
            display: flex;
            flex-direction: column;
            background: rgba(255, 255, 255, 1);
            border: 1px solid $color-regular-grey;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 8px;

            &-item {
                font-size: 16px;
                line-height: 19px;
                color: $color-dark-blue;
                padding: 12px;
                border-radius: 8px;
                transition: 0.2s ease;

                &:hover {
                    background: #eaedff;
                    color: $color-blue;
                }
            }
        }
    }
}

@media (max-width: 980px) {
    .content {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }

    .layout {
        //

        &__content {
            padding-top: 72px;
        }
    }

    .page-title {
        font-size: 36px;
        line-height: 44px;
    }

    .article {
        //

        &-title {
            font-size: 30px;
            line-height: 40px;
            text-align: left;
        }

        &-text-600 {
            line-height: 28px;
        }

        &-text18 {
            font-size: 16px;
            line-height: 26px;
        }

        &-text16 {
            line-height: 26px;
        }
    }

    .blue-block {
        padding: 56px 16px;

        &-title {
            font-size: 30px;
            line-height: 40px;
            text-align: left;
        }

        &-description {
            font-size: 16px;
            line-height: 26px;
            text-align: left;
        }
    }

    .list {
        //

        &-item {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

@keyframes do-levitation {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, 20px);
    }
}
